<template>
  <div style="padding: 20px;">
    <v-data-table
      :headers="headers"
      :items="itemsFiltered"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="ID"
      show-expand
      class="elevation-1"
      :items-per-page="-1"
      :hide-default-footer="true"
      :loading="loading"
    >
      <template v-slot:item.numGroups="{ item }">
        {{item.Groups ? item.Groups.length : ''}}
      </template>
      <template v-slot:item.numRss="{ item }">
        {{item.AutoPlayFile && item.AutoPlayFile.RSSs ? item.AutoPlayFile.RSSs.length : ''}}
      </template>
      <template v-slot:item.numHtml="{ item }">
        {{item.AutoPlayFile && item.AutoPlayFile.HTMLs ? item.AutoPlayFile.HTMLs.length : ''}}
      </template>
      <template v-slot:item.CD="{ item }">
        {{convertDatetime(item.CD)}}
      </template>
      <template v-slot:item.LMD="{ item }">
        {{convertDatetime(item.LMD)}}
      </template>
      <template v-slot:item.download="{ item }">
        <v-btn
          v-if="item.ProjectName && item.ProjectPath"
          color="primary"
          @click="handleClickDownload(item.ProjectPath, item.ProjectName)"
          x-small
        >
          Descargar
        </v-btn>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Presentaciones</v-toolbar-title>
          <v-spacer/>
          <v-btn
            elevation="2"
            :loading="loading"
            @click="handleGetData"
            color="primary"
            :disabled="loading"
            style="margin-right: 10px;"
          >
            refresh
          </v-btn>
          <search
            :onSearch="handleSearch"
            style="margin-right: 10px;"
          />
          <v-switch
            v-if="!groupID"
            v-model="singleExpand"
            label="Single expand"
            class="mt-2"
          />
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <!--small style="color:#CCCCCC">
            {{item.Project}}
          </small-->
          <html-view
            :presentationID="item.ID"
            style="margin: 20px 0 20px 0"
          />
          <rss-view
            :presentationID="item.ID"
            style="margin: 0 0 20px 0"
          />
          <group-view
            :presentationID="item.ID"
            style="margin: 0 0 20px 0"
          />
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
import Search from '@/components/Search'
import HtmlView from './HtmlView'
import RssView from './RssView'
import GroupView from './GroupView'
export default {
  props: {
    network: {
      type: String,
      requried: true,
    },
    groupID: {
      type: String,
      default: null,
    },
  },
  components: {
    Search,
    HtmlView,
    RssView,
    GroupView,
  },
  data: () => ({
    loading: false,
    singleExpand: true,
    expanded: [],
    items: [],
    itemsFiltered: [],
    searchValue: null
  }),
  computed: {
    headers () {
      return [
          {
            text: 'Nombre',
            align: 'start',
            sortable: false,
            value: 'Name',
          },
          //{ text: '[Grupos]', value: 'numGroups' },
          //{ text: '[RSS]', value: 'numRss' },
          //{ text: '[HTML]', value: 'numHtml' },
          { text: 'Descargar', value: 'download' },
          { text: 'CD', value: 'CD' },
          { text: 'LMD', value: 'LMD' },
          { text: '', value: 'data-table-expand' },
        ]
    },
  },
  watch: {
    network () {
      this.handleGetData()
    },
    groupID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    convertDatetime (v) {
      return v ? v.split('T').join(' ').split('.').shift() : null
    },
    handleSearch (v) {
      this.searchValue = v && v.length > 0 ? v : null
      this.handleFilter()
    },
    handleFilter () {
      if (!this.searchValue) {
        this.itemsFiltered = this.items
        return
      }
      const aux = this.searchValue.toUpperCase()
      this.itemsFiltered = this.items.filter(x => JSON.stringify(x).toUpperCase().indexOf(aux) >= 0)
      console.log(this.itemsFiltered)
    },
    async handleGetData () {
      this.items = []
      this.itemsFiltered = []
      this.loading = true

      //apiBrightsign.getAll(`presentations`)
      const url = this.groupID ? `/v1/private/groups/${this.groupID}/presentations` : `/v1/private/presentations/${this.network}`
      apiBrightsign.getAll(url)
        .then(response => {
          this.items = response
          this.handleFilter()
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    handleClickDownload (uri, name) {
      apiBrightsign.put(`/v1/private/presentations/prepare-download`, {uri})
        .then(response => {
          const blob = new Blob([JSON.parse(response.data)], { type: 'text/xml' })

          // Create an object URL
          const url = URL.createObjectURL(blob)
          const anchor = document.createElement('a')
          anchor.href = url
          anchor.download = name

          // Append to the DOM
          document.body.appendChild(anchor)

          // Trigger `click` event
          anchor.click()

          // Remove element from DOM
          document.body.removeChild(anchor)
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
}
</script>

